// Api BASE URLs
//export const API_URL = 'http://localhost:5000'
export const API_URL = 'https://coclaim-backend.onrender.com';
export const API_GET_DASHBOARD = "/admin/getDashboard";
export const API_GET_USER = API_URL + "/admin/getUsersWithFilter";

export const API_ADMIN_CREATE_USER = "/admin/createUser";
export const API_ADMIN_CREATE_PATIENT = "/admin/createPatient";
export const API_ADMIN_CREATE_ORDER = "/admin/createOrder";
export const API_ADMIN_CREATE_RESULT = "/order/createResult";

export const API_ADMIN_CREATE_TESTS_LAB = "/admin/createTestLab";
export const API_ADMIN_UPDATE_TESTS_LAB = "/admin/updateTestLab";
export const API_WEB_CREATE_ORDER = "/web/createOrder";
export const API_SET_ORDER_REVIEW = "/order/setReview";

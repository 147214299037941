import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  patientList: [],
  isLoading: false,
  error: null,
  patient: null,
  hcpPatientList: [],
  visits: [],
};

const patientsSlice = createSlice({
  name: "patients",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getPatientListSuccess(state, action) {
      state.isLoading = false;
      state.patientList = action.payload;
    },
    getOnePatientSuccess(state, action) {
      state.isLoading = false;
      state.patient = action.payload;
    },
    updatePatientSuccess(state, action) {
      state.isLoading = false;
      state.patient = action.payload;
    },
    updateVisitSuccess(state, action) {
      state.isLoading = false;
      const { index, updatedVisit } = action.payload;
      state.visits[index] = updatedVisit;
    },
    setHcpPatientList: (state, action) => {
      state.isLoading = false;
      state.hcpPatientList = action.payload;
    },
    addVisitSuccess(state, action) {
      state.isLoading = false;
      const newVisit = action.payload; // Assuming action.payload is the new visit data
      state.visits.push(newVisit);
    },
    addVisitFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    fetchVisitsRequest(state, action) {
      state.isLoading = true;
      state.error = null;
    },
    fetchVisitsSuccess(state, action) {
      state.isLoading = false;
      state.visits = action.payload;
    },
    fetchVisitsFailure(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    setVisits: (state, action) => {
      state.visits = action.payload;
    },
    updateVisit: (state, action) => {
      const updatedVisit = action.payload;
      state.visits = state.visits.map((visit) =>
        visit.uid === updatedVisit.uid ? updatedVisit : visit
      );
    },
  },
});

export const {
  startLoading,
  getPatientListSuccess,
  getOnePatientSuccess,
  updatePatientSuccess,
  updateVisitSuccess,
  setHcpPatientList,
  addVisitSuccess,
  addVisitFailure,
  fetchVisitsRequest,
  fetchVisitsSuccess,
  fetchVisitsFailure,
  hasError,
  setVisits,
  updateVisit,
} = patientsSlice.actions;

export default patientsSlice.reducer;

export const fetchPatientList = (queryParams) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axios.post("/patients/list", queryParams);
    dispatch(getPatientListSuccess(response.data.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

export const getOnePatient = (uid) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axios.get(`/patients/${uid}`);
    dispatch(getOnePatientSuccess(response.data.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

export const updatePatient = (patientData) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axios.put(
      `/patients/update/${patientData.uid}`,
      patientData
    );
    dispatch(updatePatientSuccess(response.data.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

export const selectHcpPatientList = (state) => state.patient.hcpPatientList;

export const fetchHcpPatientList = (queryParams) => async (dispatch) => {
  try {
    const response = await axios.post("/patients/hcpPatientList", queryParams);
    dispatch(setHcpPatientList(response.data));
  } catch (error) {
    console.error("Error fetching hcpPatientList:", error);
  }
};

export const hcpGetOnePatient = (uid) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axios.get(`/patients/hcpPatientDetail/${uid}`); // Assuming this is the correct endpoint
    dispatch(getOnePatientSuccess(response.data.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

export const hcpUpdatePatient = (patientData) => async (dispatch) => {
  try {
    const response = await axios.put(
      `/patients/hcpPatientUpdate/update/${patientData.uid}`,
      patientData
    );
    dispatch(updatePatientSuccess(response.data.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

export const addVisit = (visitData, uid) => async (dispatch) => {
  try {
    console.log("UID:", uid);
    dispatch(startLoading());
    const response = await axios.post(`/patients/addVisits/${uid}`, visitData);

    console.log("Response from server:", response.data);

    // Assuming the added visit is returned by the server
    dispatch(addVisitSuccess(response.data));

    // Update the patient with the new visit
    dispatch(hcpGetOnePatient(uid)); // Assuming getOnePatient dispatches getOnePatientSuccess
  } catch (error) {
    dispatch(addVisitFailure(error.message));
  }
};

export const fetchVisitsForPatient = (uid) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axios.get(
      `/patients/hcpPatientDetail/${uid}/visits`
    );
    console.log("API Response:", response.data);
    dispatch(fetchVisitsSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

export const hcpUpdateVisit = (visitUid, updatedVisitData) => async (
  dispatch
) => {
  try {
    console.log("Visitor UID:", visitUid);
    console.log("Updated Visit Data:", updatedVisitData);
    // Make an Axios request to update the visit in the database
    const response = await axios.put(
      `patients/visits/${visitUid}`,
      updatedVisitData
    );

    // Dispatch the updated visit data to the reducer
    dispatch({
      type: "UPDATE_VISIT",
      payload: { visitUid, updatedVisit: response.data },
    });
  } catch (error) {
    // Handle error if needed
    console.error(error);
  }
};


export const updateVisitData = (visitUid, updatedData) => async (dispatch) => {
  try {
    const response = await axios.put(`patients/visits/${visitUid}`, updatedData);
    dispatch(updateVisit(response.data));
  } catch (error) {
    console.error(error);
  }
};
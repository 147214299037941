import { format, formatDistanceToNow } from 'date-fns'

//import Moment from 'moment'

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy')
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm')
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p')
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  })
}
export function getWeekDay(days) {
  let dayName = format(new Date(), 'EEEE')
  let idex = days.filter((dayn) =>  dayn.day === dayName )
  return idex[0]
}

// // hooks
// import useAuth from '../hooks/useAuth'
// //
// import { MAvatar } from './@material-extend'
// import createAvatar from '../utils/createAvatar'

// // ----------------------------------------------------------------------

// export default function MyAvatar({ ...other }) {
//   const { user } = useAuth()
//   console.log("user =============>", user)
//   if (!user) {
//     return false;
//   }
//   if (user.user_type == '1') {
//     return (
//       <MAvatar
//         src={user?.photoURL?.path ? user.photoURL.path: "/static/brand/user.png"}
//         alt={user.firstName}
//         color={user?.photoURL?.path ? 'default' : createAvatar(user.firstName).color}
//         {...other}
//       >
//         {createAvatar(user.firstName).name}
//        {/* <p> {user.firstName} {user.lastName} </p> */}
//       </MAvatar>
//     )
//   }
//   else {
//     return (
//       <MAvatar
//         src={user.photoURL?.path ? user.photoURL.path : "/static/brand/avatar.png"}
//         alt={user.laboratoryName}
//         color={user?.photoURL?.path ? 'default' : createAvatar(user.displayName).color}
//         {...other}
//       >
//         {createAvatar(user.laboratoryName).name}
//       </MAvatar>
//     )
//   }
// }

// hooks
import useAuth from "../hooks/useAuth";
//
import { MAvatar } from "./@material-extend";
import createAvatar from "../utils/createAvatar";
import { getSingleUser } from "../redux/slices/user";
import { useDispatch, useSelector } from "../redux/store";
import { useCallback, useEffect } from "react";
import { API_URL } from "src/utils/ApiUrl";

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();
  const { userDetail } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleUser());
  }, [dispatch]);

  console.log("user =============>", userDetail?.photoURL?.path);
  if (!userDetail) {
    return false;
  }
  if (userDetail.user_type == "1") {
    return (
      <MAvatar
        src={
          userDetail?.photoURL?.path
            ? API_URL + userDetail.photoURL.path
            : "/static/brand/user.png"
        }
        alt={userDetail.firstName}
        color={
          userDetail?.photoURL?.path
            ? "default"
            : createAvatar(userDetail.firstName).color
        }
        {...other}
      >
        {createAvatar(userDetail.firstName).name}
        {/* <p> {user.firstName} {user.lastName} </p> */}
      </MAvatar>
    );
  } else {
    return (
      <MAvatar
        src={
          userDetail.photoURL?.path
            ? API_URL + userDetail.photoURL.path
            : "/static/brand/avatar.png"
        }
        alt={userDetail.laboratoryName}
        color={
          userDetail?.photoURL?.path
            ? "default"
            : createAvatar(userDetail.displayName).color
        }
        {...other}
      >
        {createAvatar(userDetail.laboratoryName).name}
      </MAvatar>
    );
  }
}

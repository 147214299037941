import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  searchResults: [],
  isLoading: false,
  error: null,
  newPatient: null,
};

const searchPatientSlice = createSlice({
  name: "searchPatient",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    searchSuccess(state, action) {
      console.log("Inside searchSuccess reducer");
      state.isLoading = false;
      const responseData = action.payload;

      // Ensure responseData is an array
      state.newPatient = Array.isArray(responseData)
        ? responseData
        : [responseData];
    },
    // searchSuccess(state, action) {
    //   console.log("Inside searchSuccess reducer"); // Add this line
    //   if (Array.isArray(action.payload)) {
    //     state.isLoading = false;
    //     state.newPatient = action.payload;
    //   } else {
    //     console.error("Invalid payload format. Expected an array.");
    //   }
    //   // state.isLoading = false;
    //   // state.newPatient = action.payload;
    // },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  startLoading,
  searchSuccess,
  hasError,
} = searchPatientSlice.actions;

export default searchPatientSlice.reducer;

export const searchPatients = (lastName) => async (dispatch) => {
  try {
    console.log("Inside searchPatients action creator"); // Add this line
    dispatch(startLoading());
    const response = await axios.post("/patients/searchPatientResult", {
      lastName,
    });
    const patientData = response.data[0];
    console.log("Response from server:", response); // Add this line
    //dispatch(searchSuccess(response.data.data));
    dispatch(searchSuccess(patientData));
  } catch (error) {
    console.error("Error in searchPatients action creator:", error); // Add this line
    dispatch(hasError(error.message));
  }
};

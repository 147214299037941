import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = {
  partnerHMOList: [],
  isLoading: false,
  error: null,
  hmo: null,
};

const hmosSlice = createSlice({
  name: "hmos",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    getPartnerHMOListSuccess(state, action) {
      state.isLoading = false;
      state.partnerHMOList = action.payload;
    },
    getOneHMOSuccess(state, action) {
      state.isLoading = false;
      state.hmo = action.payload;
      console.log("Received hmo data:", action.payload);
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const {
  startLoading,
  getPartnerHMOListSuccess,
  getOneHMOSuccess,
  hasError,
} = hmosSlice.actions;

export default hmosSlice.reducer;

export const fetchPartnerHMOList = () => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axios.post("/hmos/list");
    dispatch(getPartnerHMOListSuccess(response.data.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

export const getOneHMO = (uid) => async (dispatch) => {
  try {
    dispatch(startLoading());
    const response = await axios.get(`/hmos/${uid}`);
    dispatch(getOneHMOSuccess(response.data.data));
  } catch (error) {
    dispatch(hasError(error.message));
  }
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

export const fetchClaims = createAsyncThunk(
  "claims/fetchClaims",
  async (hcpName, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/claims/user/${hcpName}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchHMOClaims = createAsyncThunk(
  "claims/fetchHMOClaims",
  async (hmoName, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/claims/hmo/${hmoName}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchClaimDetails = createAsyncThunk(
  "claims/fetchClaimDetails",
  async (claimId, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/claims/details/${claimId}`);
      if (!response || !response.data) {
        throw new Error("No data returned from the server");
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response ? error.response.data : error.message
      );
    }
  }
);

export const updateClaim = createAsyncThunk(
  "claims/updateClaim",
  async ({ claimId, data }, { rejectWithValue }) => {
    console.log("Updating claim with ID:", claimId); // Logging the claimId
    try {
      const response = await axios.put(`/claims/update/${claimId}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  claimsList: [],
  isLoading: false,
  error: null,
  claim: null,
};

const claimsSlice = createSlice({
  name: "claims",
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    addClaimSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.claimsList.push(action.payload); // Updated to claimsList
    },
  },
  extraReducers: {
    [fetchClaims.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchClaims.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.claimsList = action.payload; // This is correct
    },
    [fetchClaims.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [fetchHMOClaims.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchHMOClaims.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.claimsList = action.payload; // or a separate state property for HMO claims
    },
    [fetchHMOClaims.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [fetchClaimDetails.pending]: (state) => {
      state.isLoading = true;
    },
    [fetchClaimDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.claim = action.payload;
    },
    [fetchClaimDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    [updateClaim.pending]: (state) => {
      state.isLoading = true;
    },
    [updateClaim.fulfilled]: (state, action) => {
      state.isLoading = false;
      const index = state.claimsList.findIndex(
        (claim) => claim._id === action.payload._id
      );
      if (index !== -1) {
        state.claimsList[index] = action.payload;
      }
    },
    [updateClaim.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export const { startLoading, hasError, addClaimSuccess } = claimsSlice.actions;

export default claimsSlice.reducer;

// Async thunk action
export const generateClaim = (claimData) => async (dispatch) => {
  dispatch(startLoading());
  try {
    const response = await axios.post("/claims/generateClaim", claimData);

    // Dispatch the generated claim data to Redux store
    dispatch(addClaimSuccess(response.data));
  } catch (error) {
    dispatch(hasError(error.message || "Could not generate claim."));
    console.error("Error generating claim:", error);
  }
};

import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";


const initialState = {
  currentChatUser: null,
  messages: [],
  users: [] // List of users for search functionality
};

const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setCurrentChatUser: (state, action) => {
      state.currentChatUser = action.payload;
    },
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
  },
});

export const { setCurrentChatUser, addMessage, setUsers, setMessages } = messageSlice.actions;
export default messageSlice.reducer;

export const fetchMessages = (user1Id, chatWith) => async (dispatch) => {
  try {
    console.log('Requesting messages with:', user1Id, chatWith);
    const response = await axios.get(`/messages/getMessages/${user1Id}/${chatWith}`);
    dispatch(setMessages(response.data.messages)); // Assuming the API returns an array of messages
  } catch (error) {
    console.error('Error fetching messages:', error);
    // Handle error appropriately
  }
};



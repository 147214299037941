import { createSlice } from "@reduxjs/toolkit";
import axios from "../../utils/axios";

const initialState = [];

const hcpPatientListReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TO_HCP_PATIENT_LIST":
      return [...state, action.payload];
    default:
      return state;
  }
};

export default hcpPatientListReducer;

export const addToHcpPatientListSuccess = (patient) => {
  return {
    type: "ADD_TO_HCP_PATIENT_LIST",
    payload: patient,
  };
};

export const hasError = (errorMessage) => {
  return {
    type: "SET_ERROR",
    payload: errorMessage,
  };
};

export const addToHcpPatientList = (patient) => async (dispatch) => {
  try {
    // Assuming there is an API endpoint to add a patient to the list
    const response = await axios.post("/patients/hcpPatientList/addPatient", {
      patient,
    });

    dispatch(addToHcpPatientListSuccess(patient));
  } catch (error) {
    console.error("Error in addToHcpPatientList action creator:", error);
    dispatch(hasError(error.message));
  }
};

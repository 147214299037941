import axios from 'axios'
import { API_URL } from './ApiUrl'
// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  // baseURL: `${API_URL}/api`,
  baseURL: `${API_URL}` + '/api',
  headers: {
    'Content-Type': 'application/json'
  }
})

axiosInstance.interceptors.response.use(
  response => response,
  error => Promise.reject((error.response && error.response.data) || 'Something went wrong')
)

export default axiosInstance

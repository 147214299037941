// routes
import { PATH_DASHBOARD } from '../../routes/paths'
// components
import SvgIconStyle from '../../components/SvgIconStyle'

// ----------------------------------------------------------------------

const getIcon = name => <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  order: getIcon('ic_kanban'),
  payment: getIcon('invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  kanban: getIcon('ic_kanban')
}

const HMOSidebarConfig = [
  
  {
    // subheader: 'general',
    items: [
      {
        title: 'Overview',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      { title: 'Customers ', path: PATH_DASHBOARD.patient.list, icon: ICONS.user },
      { title: 'Care Providers', path: PATH_DASHBOARD.hcp.list, icon: ICONS.payment },
      { title: 'Claims', path: PATH_DASHBOARD.claims.hmoList, icon: ICONS.ecommerce },
      { title: 'Messages', path: PATH_DASHBOARD.messages.chat, icon: ICONS.mail },
      { title: 'Settings', path: PATH_DASHBOARD.user.account, icon: ICONS.kanban },
    ]
  }
]

export default HMOSidebarConfig

import { Suspense, lazy } from "react";
import { useRoutes, useLocation, useNavigate } from "react-router-dom";
//import Navigate from 'react-router-dom';

// layouts

import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
// guards
import GuestGuard from "../guards/GuestGuard";
import AuthGuard from "../guards/AuthGuard";
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components
import LoadingScreen from "../components/LoadingScreen";
//import PartnerHCPList from "src/pages/dashboard/partnerHCPList";
//import AllHCPs from "src/pages/dashboard/allHCPs";
// import PatientList from "src/pages/dashboard/patientList";
// import Quality from 'src/pages/Quality'

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/dashboard");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  //const navigate = useNavigate();
  return useRoutes([
    {
      path: "auth",
      children: [
        {
          path: "login",
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: "register",
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: "register-hmo",
          element: (
            <GuestGuard>
              <RegisterHMO />
            </GuestGuard>
          ),
        },
        {
          path: "register-hcp",
          element: (
            <GuestGuard>
              <RegisterHCP />
            </GuestGuard>
          ),
        },
        { path: "login-unprotected", element: <Login /> },
        { path: "register-unprotected", element: <Register /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "reset-password/:token", element: <ResetPassword /> },
        { path: "verify", element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        // { path: "/dashboard", element: <PatientList /> },
        // { path: "app", element: <PatientList /> },
        { path: "/dashboard", element: <DashboardComponent /> },
        { path: "app", element: <DashboardComponent /> },
        
        // { path: "/dashboard", element: <div>Under Construction.</div> },
        // { path: "app", element: <div>App content is not available right now.</div> },        

        {
          path: "user",
          children: [
            {
              path: "/dashboard/user",
              element: <UserProfile />,
            },
            { path: "profile", element: <UserProfile /> },
            { path: "cards", element: <UserCards /> },
            { path: "list", element: <UserList /> },
            { path: "new", element: <UserCreate /> },
            { path: ":name/edit", element: <UserCreate /> },
            { path: "account", element: <UserAccount /> },
          ],
        },
        {
          path: "patient",
          children: [
            // { path: '/', element: <Navigate to="/dashboard/user/profile" replace /> },
            // { path: 'profile', element: <UserProfile /> },
            // { path: 'cards', element: <UserCards /> },
            { path: "list", element: <PatientList /> },
            { path: "hcpPatientList", element: <HCPPatientList /> },
            { path: "searchPatient", element: <PatientSearch /> },
            { path: "searchPatientResult", element: <PatientSearchResult /> },
            { path: "detail/:uid", element: <PatientDetail /> },
            { path: "hcpPatientDetail/:uid", element: <HCPPatientDetail /> },
            { path: "new", element: <PatientCreate /> },
            { path: ":id/edit", element: <PatientCreate /> },
            // { path: 'account', element: <UserAccount /> }
          ],
        },
        {
          path: "hcp",
          children: [
            { path: "list", element: <PartnerHCPList /> },
            { path: "all", element: <AllHCPs /> },
            { path: "detail/:uid", element: <HCPDetails /> },
            { path: "new", element: <PatientCreate /> },
            { path: ":id/edit", element: <HCPDetails /> },
          ],
        },
        {
          path: "hmo",
          children: [
            { path: "list", element: <PartnerHMOList /> },
            { path: "detail/:uid", element: <HMODetails /> },
            { path: "new", element: <PatientCreate /> },
            { path: ":id/edit", element: <HCPDetails /> },
          ],
        },
        {
          path: "claims",
          children: [
            { path: "list", element: <HCPClaimsList /> },
            { path: "hmoList", element: <HMOClaimsList /> },
            { path: "details/:claimId", element: <ClaimDetails /> },
            { path: "new", element: <PatientCreate /> },
            { path: ":id/edit", element: <HCPDetails /> },
          ],
        },
        {
          path: "messages",
          element: <MessagingPage />, 
          children: [{ path: "chat", element: <MessagingPage /> }],
        },

        // { path: 'kanban', element: <Kanban /> }
      ],
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoon /> },
        // { path: 'maintenance', element: <Maintenance /> },
        // { path: 'pricing', element: <Pricing /> },
        // { path: 'payment', element: <Payment /> },
        // { path: '500', element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        {
          path: "/*",
          element: <NotFound />,
        },
      ],
    },

    {
      path: "/",
      element: <MainLayout />,
      children: [
        { path: "/", element: <LandingPage /> },
        // { path: 'about-us', element: <About /> },
        // { path: 'contact-us', element: <Contact /> },
        // { path: 'quality-standard', element: <Quality /> },
        // { path: 'faqs', element: <Faqs /> },
        // { path: 'searchlab', element: <SearchLab /> },
        {
          path: "components",
          children: [
            // { path: '/', element: <ComponentsOverview /> },
            // FOUNDATIONS
            { path: "color", element: <Color /> },
            { path: "typography", element: <Typography /> },
            { path: "shadows", element: <Shadows /> },
            { path: "grid", element: <Grid /> },
            { path: "icons", element: <Icons /> },
            // MATERIAL UI
            { path: "accordion", element: <Accordion /> },
            { path: "alert", element: <Alert /> },
            { path: "autocomplete", element: <Autocomplete /> },
            { path: "avatar", element: <Avatar /> },
            { path: "badge", element: <Badge /> },
            { path: "breadcrumbs", element: <Breadcrumb /> },
            { path: "buttons", element: <Buttons /> },
            { path: "checkbox", element: <Checkbox /> },
            { path: "chip", element: <Chip /> },
            { path: "dialog", element: <Dialog /> },
            { path: "label", element: <Label /> },
            { path: "list", element: <List /> },
            { path: "menu", element: <Menu /> },
            { path: "pagination", element: <Pagination /> },
            { path: "pickers", element: <Pickers /> },
            { path: "popover", element: <Popover /> },
            { path: "progress", element: <Progress /> },
            { path: "radio-button", element: <RadioButtons /> },
            { path: "rating", element: <Rating /> },
            { path: "slider", element: <Slider /> },
            { path: "snackbar", element: <Snackbar /> },
            { path: "stepper", element: <Stepper /> },
            { path: "switch", element: <Switches /> },
            { path: "table", element: <Table /> },
            { path: "tabs", element: <Tabs /> },
            { path: "textfield", element: <Textfield /> },
            { path: "timeline", element: <Timeline /> },
            { path: "tooltip", element: <Tooltip /> },
            { path: "transfer-list", element: <TransferList /> },
            { path: "tree-view", element: <TreeView /> },
            // EXTRA COMPONENTS
            { path: "chart", element: <Charts /> },
            { path: "map", element: <Map /> },
            { path: "editor", element: <Editor /> },
            { path: "copy-to-clipboard", element: <CopyToClipboard /> },
            { path: "upload", element: <Upload /> },
            { path: "carousel", element: <Carousel /> },
            { path: "multi-language", element: <MultiLanguage /> },
            { path: "animate", element: <Animate /> },
            { path: "mega-menu", element: <MegaMenu /> },
          ],
        },
      ],
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../pages/authentication/Login")));
const Register = Loadable(
  lazy(() => import("../pages/authentication/Register"))
);
const RegisterHMO = Loadable(
  lazy(() => import("../pages/authentication/RegisterHMO"))
);
const RegisterHCP = Loadable(
  lazy(() => import("../pages/authentication/RegisterHCP"))
);
const ForgotPassword = Loadable(
  lazy(() => import("../pages/authentication/ForgotPassword"))
);
const ResetPassword = Loadable(
  lazy(() => import("../pages/authentication/ResetPassword"))
);
const VerifyCode = Loadable(
  lazy(() => import("../pages/authentication/VerifyCode"))
);
// Dashboard
const GeneralApp = Loadable(
  lazy(() => import("../pages/dashboard/GeneralApp"))
);

const DashboardComponent = Loadable(
  lazy(() => import("../pages/dashboard/dashboardComponent"))
);

const UserProfile = Loadable(
  lazy(() => import("../pages/dashboard/UserProfile"))
);
const UserCards = Loadable(lazy(() => import("../pages/dashboard/UserCards")));

const UserAccount = Loadable(
  lazy(() => import("../pages/dashboard/UserAccount"))
);
const UserCreate = Loadable(
  lazy(() => import("../pages/dashboard/UserCreate"))
);
const UserList = Loadable(lazy(() => import("../pages/dashboard/UserList")));

const PatientCreate = Loadable(
  lazy(() => import("../pages/dashboard/PatientCreate"))
);
const PatientSearch = Loadable(
  lazy(() => import("../pages/dashboard/patientSearch"))
);
const PatientSearchResult = Loadable(
  lazy(() => import("../pages/dashboard/patientSearchResult"))
);
const HCPPatientDetail = Loadable(
  lazy(() => import("../pages/dashboard/hcpPatientDetail"))
);

const HCPClaimsList = Loadable(
  lazy(() => import("../pages/dashboard/hcpClaimsList"))
);

const HMOClaimsList = Loadable(
  lazy(() => import("../pages/dashboard/hmoClaimsList"))
);

const ClaimDetails = Loadable(
  lazy(() => import("../pages/dashboard/claimDetails"))
);

const MessagingPage = Loadable(
  lazy(() => import("../pages/dashboard/messagingPage"))
);

const PatientList = Loadable(
  lazy(() => import("../pages/dashboard/patientList"))
);
const HCPPatientList = Loadable(
  lazy(() => import("../pages/dashboard/hcpPatientList"))
);
const PatientView = Loadable(
  lazy(() => import("../pages/dashboard/HCPDetails"))
);
const PatientDetail = Loadable(
  lazy(() => import("../components/_dashboard/patient/patientDetail"))
);

const PartnerHCPList = Loadable(
  lazy(() => import("../pages/dashboard/partnerHCPList"))
);

const PartnerHMOList = Loadable(
  lazy(() => import("../pages/dashboard/partnerHMOList"))
);

const AllHCPs = Loadable(lazy(() => import("../pages/dashboard/allHCPs")));
const HCPDetails = Loadable(
  lazy(() => import("../pages/dashboard/HCPDetails"))
);

const HMODetails = Loadable(
  lazy(() => import("../pages/dashboard/HMODetails"))
);

// const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Docs
// const Docs = Loadable(lazy(() => import('../pages/Docs')))
// Main
const LandingPage = Loadable(lazy(() => import("../pages/LandingPage")));
// const About = Loadable(lazy(() => import('../pages/About')))
// const Quality = Loadable(lazy(() => import('../pages/Quality')))
// const Contact = Loadable(lazy(() => import('../pages/Contact')))
// const Faqs = Loadable(lazy(() => import('../pages/Faqs')))
// const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')))
// const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')))
// const Pricing = Loadable(lazy(() => import('../pages/Pricing')))
// const Payment = Loadable(lazy(() => import('../pages/Payment')))
// const Page500 = Loadable(lazy(() => import('../pages/Page500')))
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
// Components
// const ComponentsOverview = Loadable(lazy(() => import('../pages/ComponentsOverview')))
const Color = Loadable(
  lazy(() => import("../pages/components-overview/foundations/FoundationColor"))
);
const Typography = Loadable(
  lazy(() =>
    import("../pages/components-overview/foundations/FoundationTypography")
  )
);
const Shadows = Loadable(
  lazy(() =>
    import("../pages/components-overview/foundations/FoundationShadows")
  )
);
const Grid = Loadable(
  lazy(() => import("../pages/components-overview/foundations/FoundationGrid"))
);
const Icons = Loadable(
  lazy(() => import("../pages/components-overview/foundations/FoundationIcons"))
);
const Accordion = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Accordion"))
);
const Alert = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Alert"))
);
const Autocomplete = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Autocomplete"))
);
const Avatar = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Avatar"))
);
const Badge = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Badge"))
);
const Breadcrumb = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Breadcrumb"))
);
const Buttons = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/buttons"))
);
const Checkbox = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Checkboxes"))
);
const Chip = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/chips"))
);
const Dialog = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/dialog"))
);
const Label = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Label"))
);
const List = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Lists"))
);
const Menu = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Menus"))
);
const Pagination = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Pagination"))
);
const Pickers = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/pickers"))
);
const Popover = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Popover"))
);
const Progress = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/progress"))
);
const RadioButtons = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/RadioButtons"))
);
const Rating = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Rating"))
);
const Slider = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Slider"))
);
const Snackbar = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Snackbar"))
);
const Stepper = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/stepper"))
);
const Switches = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Switches"))
);
const Table = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/table"))
);
const Tabs = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Tabs"))
);
const Textfield = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/textfield"))
);
const Timeline = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Timeline"))
);
const Tooltip = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/Tooltip"))
);
const TransferList = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/transfer-list"))
);
const TreeView = Loadable(
  lazy(() => import("../pages/components-overview/material-ui/TreeView"))
);
const Charts = Loadable(
  lazy(() => import("../pages/components-overview/extra/Charts"))
);
const Map = Loadable(
  lazy(() => import("../pages/components-overview/extra/Map"))
);
const Editor = Loadable(
  lazy(() => import("../pages/components-overview/extra/Editor"))
);
const CopyToClipboard = Loadable(
  lazy(() => import("../pages/components-overview/extra/CopyToClipboard"))
);
const Upload = Loadable(
  lazy(() => import("../pages/components-overview/extra/Upload"))
);
const Carousel = Loadable(
  lazy(() => import("../pages/components-overview/extra/Carousel"))
);
const MultiLanguage = Loadable(
  lazy(() => import("../pages/components-overview/extra/MultiLanguage"))
);
const Animate = Loadable(
  lazy(() => import("../pages/components-overview/extra/animate"))
);
const MegaMenu = Loadable(
  lazy(() => import("../pages/components-overview/extra/MegaMenu"))
);
